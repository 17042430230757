<template>
  <div class="uk-container uk-container-expand">
    <div class="uk-grid uk-child-width-1-1 uk-grid-small">
      <div>
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div>
            <h3 class="uk-h3 uk-margin-remove">
              <span data-uk-icon="icon: wordpress;ratio: 1.5"></span> {{ headerLabel }}
            </h3>
          </div>
          <div>
            <router-link :to="{name: 'blog'}" class="uk-margin-right uk-button uk-button-text">
              abbrechen
            </router-link>
            <!--
            <a :href="$store.state.auth.user.data.params.homepage + '/blog/' + post.slug + '?preview=true'" target="_blank" class="uk-button uk-button-default uk-button-small button-rounded button-shadow uk-margin-small-right">
              <img src="/img/eye.svg" data-uk-svg style="width:26px;">
              Vorschau
            </a>
            -->
            <div class="uk-button-group">
              <button
                class="uk-button uk-button-primary"
                @click.prevent="store"
                :disabled="submitting"
              >Speichern</button>
              <div class="uk-inline">
                <button class="uk-button uk-button-primary" type="button" :disabled="submitting">
                  <span uk-icon="icon: triangle-down"></span>
                </button>
                <div
                  ref="storeDropdown"
                  uk-dropdown="animation: uk-animation-slide-top-small; duration: 200;delay-hide: 0;mode: click; boundary: ! .uk-button-group; boundary-align: true;pos: bottom-right"
                >
                  <ul class="uk-nav uk-dropdown-nav">
                    <li>
                      <a href="#" @click.prevent="storeAndClose">Speichern und Schließen</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-form-stacked uk-margin-top uk-margin-large-bottom">
        <div class="uk-grid" data-uk-grid>
            <div class="uk-width-3-4">
              <div>
                <div class="uk-margin">
                  <input class="uk-input" type="text" placeholder="Titel" v-model="post.title">
                </div>
                <ul data-uk-tab>
                  <li><a href="#">Inhalt</a></li>
                  <li><a href="#">Veröffentlichung</a></li>
                </ul>
              <ul class="uk-switcher uk-margin">
                <li>
                  <div>
                    <editor :init="initEditor" :id="'post-' + post.id" v-model="post.body"></editor>
                  </div>
                  </li>
                  <li>
                    <form class="uk-form-horizontal uk-padding-small">
                      <div class="uk-margin">
                        <label class="uk-form-label">Meta Titel</label>
                          <div class="uk-form-controls">
                            <input class="uk-input" type="text" v-model="post.data.meta.title">
                          </div>
                        </div>

                        <div class="uk-margin">
                          <label class="uk-form-label">Meta Beschreibung</label>
                          <div class="uk-form-controls">
                            <textarea class="uk-textarea" rows="5" v-model="post.data.meta.description"></textarea>
                          </div>
                        </div>

                        <div class="uk-margin">
                          <label class="uk-form-label">Meta Keywords</label>
                          <div class="uk-form-controls">
                            <input class="uk-input" type="text" v-model="post.data.meta.keywords">
                          </div>
                        </div>
                      </form>
                  </li>
              </ul>
              </div>
            </div>
            <div class="uk-width-1-4">
              <div class="uk-background-default uk-padding-small">
                <form class="uk-form-stacked">
                    <div class="uk-margin">
                        <label class="uk-form-label">Alias (SEO)</label>
                        <div class="uk-form-controls">
                            <input class="uk-input" type="text" v-model="post.slug">
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Bild</label>
                        <div class="uk-form-controls">
                          <select-image :source="post.data.image.src" @image-selected="onImageSelected" @image-removed="onImageRemoved" />
                            <input type="text" class="uk-input" placeholder="Alt Text" v-model="post.data.image.alt">
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Veröffentlicht am</label>
                        <date-picker
                          :attributes="datepickerAttr"
                          :input-props='{class: "uk-input"}'
                          :popover="{ visibility: 'click' }"
                          locale="de"
                          v-model="publishedDate"
                        />
                      </div>
                    <div class="uk-margin">
                        <div class="uk-form-label">Status</div>
                        <label class="uk-switch">
                          <input type="checkbox" v-model="post.active">
                          <div class="uk-switch-slider uk-switch-on-off round"></div>
                        </label>
                    </div>
                  </form>
                </div>
              </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import PageService from '@/services/page.service'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
// import Editor from "@/components/Editor";
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver/theme'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/image'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/autoresize'
// import 'tinymce/plugins/preview';
import Editor from '@tinymce/tinymce-vue'

import Upload from '@/components/Upload'
import SelectImage from '@/components/finder/SelectImage'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment'
export default {
  components: {
    vSelect,
    Editor,
    Upload,
    SelectImage,
    DatePicker
  },
  data () {
    return {
      postId: this.$route.params.id,
      submitting: false,
      post: {
        id: null,
        active: false,
        body: '',
        data: {
          image: {
            id: null,
            src: '',
            alt: ''
          },
          meta: {
            title: '',
            description: '',
            keywords: ''
          }
        }
      },
      datepickerAttr: [{
        key: 'today',
        dot: true,
        dates: new Date()
      }],
      formErrors: {},
      closeAfterSave: false,
      initEditor: {
        menubar: false,
        branding: false,
        pagebreak_split_block: true,
        relative_urls: false,
        document_base_url: '/',
        min_height: 425,
        max_height: 800,
        toolbar: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link neqstImage | pagebreak code',
        plugins: 'code link pagebreak autoresize',
        autoresize_bottom_margin: 50,
        language_url: '/tinymce/langs/de.js',
        language: 'de',
        skin_url: '/tinymce/skins/ui/oxide',
        content_css: '/tinymce/skins/content/default/content.min.css',
        external_plugins: {
          neqstImage: '/tinymce/plugins/image-picker/plugin.js'
        }
      }
    }
  },
  mounted () {
    if (this.postId) {
      this.getPost()
    }
  },
  computed: {
    headerLabel () {
      return this.postId ? 'Beitrag bearbeiten' : 'Beitrag erstellen'
    },
    publishedDate: {
      get () {
        return this.post.publishedAt ? new Date(this.post.publishedAt) : new Date()
      },
      set (value) {
        this.post.publishedAt = moment(value).format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    async getPost () {
      const post = await PageService.getBlogPost(this.postId)

      this.post = _.assign({}, this.post, post.data)
    },
    store () {
      this.submitting = true

      PageService.storeBlogPost(this.post).then(response => {
        this.post = response.data
        this.submitting = false
        iziToast.success({
          title: 'OK',
          message: 'Beitrag gespeichert!',
          timeout: 3000,
          position: 'topRight',
          transitionIn: 'fadeInLeft'
        })

        if (this.closeAfterSave) {
          this.$router.replace({ name: 'blog' })
          return
        }

        if (!this.pageId) {
          this.$router.replace({ name: 'blog-edit', params: { id: response.data.id } })
        }
      })
    },
    storeAndClose () {
      this.closeAfterSave = true
      this.store()
    },
    onImageSelected (image) {
      this.post.data.image.src = image
    },
    onImageRemoved () {
      this.post.data.image.src = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.uk-button-default:hover {
  box-shadow: 0 0 0 1px rgba(197, 197, 197, 0.85),
    0 2px 5px 0 rgba(197, 197, 197, 0.85), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
    0 1px 2px 0 rgba(0, 0, 0, 0.08);
}
.uk-button-small {
  border-radius: 3px;
  padding: 3px 14px;
  font-size: 1rem;
  text-transform: none;
  &.muted {
    color: #999;
  }
}
.button-shadow {
    box-shadow: 0 0 0 1px rgba(43,45,80,.1), 0 2px 5px 0 rgba(43,45,80,.08), 0 1px 1.5px 0 rgba(0,0,0,.07), 0 1px 2px 0 rgba(0,0,0,.08);
    border: none;
}
</style>
